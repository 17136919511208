/* 

Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 


- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 12

*/

/* Bellow 1200px */
@media (max-width: 75em) {
  html {
    /* 9px / 16px  */
    font-size: 56.25%;
  }
}

/* Bellow 946px */
@media (max-width: 59em) {
  .heading-secondary {
    font-size: 2.2rem;
  }

  .nav-logo-img {
    display: block;
    height: 6rem;
  }

  .main-nav-list {
    gap: 3.8rem;
  }

  .nav-link:link,
  .nav-link:visited {
    font-size: 1.8rem;
  }

  .services .list-item {
    gap: 1.2rem;
  }

  /* .map-directions {
    min-height: ;
  } */
}

/* Bellow 792 */
@media (max-width: 49.5em) {
  html {
    font-size: 50%;
  }

  .btn-directions,
  .btn-form,
  .btn-tab {
    padding: 1.6rem 4rem;
  }

  .btn-mobile-nav {
    display: block;
    z-index: 999;
  }

  .main-nav {
    background: linear-gradient(to left, #ece9e698, rgba(255, 255, 255, 0.863));
    backdrop-filter: blur(12px);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    pointer-events: none;
    visibility: 0;
  }

  .main-nav-list {
    flex-direction: column;
    width: 100%;
    gap: 15rem;
    justify-content: center;
  }

  .nav-link:link,
  .nav-link:visited {
    font-size: 3rem;
    font-weight: 600;
    color: #555;
  }

  .nav-link-contact:link,
  .nav-link-contact:visited {
    display: flex;
    gap: 1.2rem;
    color: #fff;
    background: radial-gradient(circle at center, #75826b, #99a188);
    border-radius: 9px;
    padding: 0.8rem 2.4rem;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  .nav-open .icon-mobile-nav[name='menu-outline'] {
    display: none;
  }

  .nav-open .icon-mobile-nav[name='close-outline'] {
    display: block;
  }

  .about-tab-container {
    gap: 8rem;
  }

  .btn-tab {
    padding: 1.4rem 1.6rem;
  }

  .services-list {
    flex-direction: column;
    gap: 0;
    font-size: 1.8rem;
    padding: 4rem 4rem;
  }

  .btn-close {
    right: 3%;
  }

  .dot-container {
    gap: 1.3rem;
  }

  .slider-btn--left {
    left: 1%;
  }

  .slider-btn--right {
    right: 1%;
  }

  /* .slider-btn--right {
    right: -10%;
  } */

  .worksafe-img {
    width: 80%;
  }

  .contact {
    grid-template-columns: 1fr;
  }

  .contacts-container {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  /* .contact .heading-secondary {
    margin-bottom: 2rem;
  } */

  .contact-box {
    display: block;
    gap: 4.4rem;
  }

  .contact-email,
  .contact-tel {
    padding: 2rem 3.6rem;
  }
}

/* bellow 500px */
@media (max-width: 34em) {
  .heading-secondary {
    font-size: 2rem;
    margin-bottom: 3.2rem;
  }

  .nav-logo-img {
    height: 5rem;
  }

  .hero-slider-container {
    padding: 3rem 0;
  }

  .services-left ul,
  .services-right ul {
    text-decoration: none;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.5rem;
  }

  .about-box {
    min-height: 0;
  }

  .about-tab-container {
    gap: 1.8rem;
  }

  .btn-tab-content {
    padding: 2rem 2rem;
  }

  .services-list {
    padding: 4rem 2rem;
  }

  .btn-close {
    right: 3%;
  }

  .dot-container {
    gap: 0.8rem;
  }

  .portfolio-box {
    padding: 3.2rem;
  }

  .portfolio-description {
    font-size: 1.6rem;
    margin-bottom: 1.4rem;
  }

  .contact .heading-secondary {
    margin-bottom: 3.2rem;
  }

  .contacts-container {
    gap: 3.2rem;
  }

  form[name='contact-form'] input {
    height: 6rem;
  }
}
