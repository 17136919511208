@media (width <= 75em) {
  html {
    font-size: 56.25%;
  }
}

@media (width <= 59em) {
  .heading-secondary {
    font-size: 2.2rem;
  }

  .nav-logo-img {
    height: 6rem;
    display: block;
  }

  .main-nav-list {
    gap: 3.8rem;
  }

  .nav-link:link, .nav-link:visited {
    font-size: 1.8rem;
  }

  .services .list-item {
    gap: 1.2rem;
  }
}

@media (width <= 49.5em) {
  html {
    font-size: 50%;
  }

  .btn-directions, .btn-form, .btn-tab {
    padding: 1.6rem 4rem;
  }

  .btn-mobile-nav {
    z-index: 999;
    display: block;
  }

  .main-nav {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    visibility: 0;
    background: linear-gradient(to left, #ece9e698, #ffffffdc);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }

  .main-nav-list {
    flex-direction: column;
    justify-content: center;
    gap: 15rem;
    width: 100%;
  }

  .nav-link:link, .nav-link:visited {
    color: #555;
    font-size: 3rem;
    font-weight: 600;
  }

  .nav-link-contact:link, .nav-link-contact:visited {
    color: #fff;
    background: radial-gradient(circle, #75826b, #99a188);
    border-radius: 9px;
    gap: 1.2rem;
    padding: .8rem 2.4rem;
    display: flex;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .about-tab-container {
    gap: 8rem;
  }

  .btn-tab {
    padding: 1.4rem 1.6rem;
  }

  .services-list {
    flex-direction: column;
    gap: 0;
    padding: 4rem;
    font-size: 1.8rem;
  }

  .btn-close {
    right: 3%;
  }

  .dot-container {
    gap: 1.3rem;
  }

  .slider-btn--left {
    left: 1%;
  }

  .slider-btn--right {
    right: 1%;
  }

  .worksafe-img {
    width: 80%;
  }

  .contact {
    grid-template-columns: 1fr;
  }

  .contacts-container {
    justify-content: space-between;
    align-items: start;
    display: flex;
  }

  .contact-box {
    gap: 4.4rem;
    display: block;
  }

  .contact-email, .contact-tel {
    padding: 2rem 3.6rem;
  }
}

@media (width <= 34em) {
  .heading-secondary {
    margin-bottom: 3.2rem;
    font-size: 2rem;
  }

  .nav-logo-img {
    height: 5rem;
  }

  .hero-slider-container {
    padding: 3rem 0;
  }

  .services-left ul, .services-right ul {
    flex-direction: column;
    flex-grow: 1;
    gap: .5rem;
    text-decoration: none;
    list-style: none;
    display: flex;
  }

  .about-box {
    min-height: 0;
  }

  .about-tab-container {
    gap: 1.8rem;
  }

  .btn-tab-content {
    padding: 2rem;
  }

  .services-list {
    padding: 4rem 2rem;
  }

  .btn-close {
    right: 3%;
  }

  .dot-container {
    gap: .8rem;
  }

  .portfolio-box {
    padding: 3.2rem;
  }

  .portfolio-description {
    margin-bottom: 1.4rem;
    font-size: 1.6rem;
  }

  .contact .heading-secondary {
    margin-bottom: 3.2rem;
  }

  .contacts-container {
    gap: 3.2rem;
  }

  form[name="contact-form"] input {
    height: 6rem;
  }
}

/*# sourceMappingURL=index.d98f8355.css.map */
